import React, { Component } from 'react';
import axios from "axios";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import Auxilary from "../Auxilary/Auxilary";
import PageTitle from "../Auxilary/PageTitle";

import "../../custom.css";
import "../Shared.css";

export class Tag extends Component {
    static displayName = Tag.name;

    state = {
        isLoading: true,
        isError: false,
        blogs: []
    }

    componentDidMount() {
        PageTitle.setTitle();

        axios.get('/tags/' + this.props.match.params.id)
            .then((response) => {
                if (response.status === 200) {
                    //const html = response.data;
                    //console.log(response.data);
                    this.setState({ isLoading: false, blogs: response.data });
                } else {
                    this.setState({ isLoading: false, isError: true });
                }
            },
                (error) => {
                    this.setState({ isLoading: false, isError: true });
                    console.log(error);
                })
    }

    static renderBlogPostPreviews(blogs) {
        return (<Auxilary>
            {blogs.map(post =>
                <div key={post.id} className="topicDiv">
                    <header>
                        <h3 className="topicHeader">
                            <NavLink className="topicNavLink" tag={Link} to={"/blog/" + post.id}>{post.header}</NavLink>
                        </h3>
                        <small>{new Date(Date.parse(post.date)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                    </header>
                    <section>
                        <p className="topicSummary">{post.summary}</p>
                    </section>
                </div>
            )}
            </Auxilary>
        );
    }

    render() {
        var content = "Loading...";
        if (this.state.isError) {
            content = "Ooops... something went wrong. Please visit back a little bit later.";
        } else if (!this.state.isLoading) {
            content = Tag.renderBlogPostPreviews(this.state.blogs);
        }

        return (
            <Auxilary>
                {content}
            </Auxilary>
        );
    }
}
