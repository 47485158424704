import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Blogs } from './components/Blogs/Blogs';
import { ForgeDocs } from './components/Forge/ForgeDocs';
import { ForgeTopic } from './components/Forge/ForgeTopic';
import { Blog } from "./components/Blogs/Blog";
import { Tag } from "./components/Blogs/Tag";
import { Switch } from "react-router-dom";

import './custom.css';

export default class App extends Component {
    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Blogs} />
                    <Route path='/blog/:id' component={Blog} />
                    <Route path='/tag/:id' component={Tag} />
                    <Route exact path='/forgedocs' component={ForgeDocs} />
                    <Route path='/forgetopic/:id' component={ForgeTopic} />
                    <Route render={() => <h3 style={{textAlign: 'center'}}>Requested content not found. Please check your URL.</h3>} />
                </Switch>
            </Layout>
        );
    }
}
