class PageTitle {

    static setTitle(title = "Zoltán Juhász's blog (JZO) | IT Tech Blog") {
        document.title = title;
        document.querySelector("meta[property='og:title']").content = title;
    }

}

export default PageTitle;
