import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

import Auxilary from "./Auxilary/Auxilary";

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <Auxilary>
                <NavMenu />
                <Container>
                    {this.props.children}
                </Container>
            </Auxilary>
        );
    }
}
