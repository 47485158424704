import React, { Component } from 'react';
import axios from "axios";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import Auxilary from "../Auxilary/Auxilary";
import PageTitle from "../Auxilary/PageTitle";

import "../Shared.css";

export class ForgeDocs extends Component {

    state = {
        isLoading: true,
        isError: false,
        docs: []
    }

    componentDidMount() {
        PageTitle.setTitle();

        axios.get('/forgetopics')
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ isLoading: false, docs: response.data });
                } else {
                    this.setState({ isLoading: false, isError: true });
                }
            },
                (error) => {
                    this.setState({ isLoading: false, isError: true });
                    console.log(error);
                })
    }

    static renderForgeDocsPreviews(docs) {
        return (<Auxilary>
            {docs.map(doc =>
                <div key={doc.id} className="topicDiv">
                    <header>
                        <h3 className="topicHeader">
                            <NavLink className="topicNavLink" tag={Link} to={"/forgetopic/" + encodeURIComponent(doc.id)}>{doc.header}</NavLink>
                        </h3>
                    </header>
                    <section>
                        <p className="topicSummary">{doc.summary}</p>
                    </section>
                </div>
            )}
        </Auxilary>
        );
    }

    render() {
        var content = "Loading...";
        if (this.state.isError) {
            content = "Ooops... something went wrong. Please visit back a little bit later.";
        } else if (!this.state.isLoading) {
            content = ForgeDocs.renderForgeDocsPreviews(this.state.docs);
        }

        return (
            <Auxilary>
                {content}
            </Auxilary>
        );
    }

}
