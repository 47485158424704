import React, { Component } from "react";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from "axios";

import Auxilary from "../Auxilary/Auxilary";
import PageTitle from "../Auxilary/PageTitle";
import { Footer } from "../Footer";

import "./Blog.css";
import "../Shared.css";

export class Blog extends Component {

    state = {
        isLoading: true,
        isError: false,
        post: {}
    }

    componentDidMount() {
        this.loadBlogContent(this.props.match.params.id);
    }

    loadBlogContent(id) {
        PageTitle.setTitle();

        axios.get('/BlogEntry/' + id)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ isLoading: false, post: response.data });
                } else {
                    this.setState({ isLoading: false, isError: true });
                }
            },
                (error) => {
                    this.setState({ isLoading: false, isError: true });
                    console.log(error);
                })
    }

    prevNextClickEventHandler = (id) => {
        this.setState({ isLoading: true, isError: false, post: {} });
        this.loadBlogContent(id);
    }

    static renderPostContent(post, clickEventHandler) {
        PageTitle.setTitle(post.header + " | " + document.title);

        let prevLi = { display: 'none' };
        if (post.prevId !== -1) prevLi.display = 'list-item';

        let nextLi = { display: 'none' };
        if (post.nextId !== -1) nextLi.display = "list-item";

        return (
            <Auxilary>
                <section>
                    <header>
                        <h1 className="blogHeader">{post.header}</h1>
                        <small>{new Date(Date.parse(post.date)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                    </header>
                </section>
                <section>
                    <div className="tags">
                        {post.tags.map(tag =>
                            <NavLink key={tag} className="tag" tag={Link} to={"/tag/" + encodeURIComponent(tag)}>{tag}</NavLink>)
                        }
                    </div>
                </section>
                <section>
                    <main dangerouslySetInnerHTML={{ __html: post.content }}></main>
                </section>
                <section>
                    <Footer />
                    <nav>
                        <ul className="footerNav">
                            <li style={prevLi}><NavLink onClick={() => clickEventHandler(post.prevId)} className="footerNavLink" tag={Link} to={"/blog/" + post.prevId}>← {post.prevHeader}</NavLink></li>
                            <li style={nextLi}><NavLink onClick={() => clickEventHandler(post.nextId)} className="footerNavLink" tag={Link} to={"/blog/" + post.nextId}>{post.nextHeader} →</NavLink></li>
                        </ul>
                    </nav>
                </section>
            </Auxilary>
        );
    }

    render() {
        var content = "Loading post ...";
        if (this.state.isError) {
            content = "Ooops... something went wrong. Please visit back a little bit later.";
        } else if (!this.state.isLoading) {
            content = Blog.renderPostContent(this.state.post, this.prevNextClickEventHandler);
        }

        return (
            <Auxilary>
                {content}
            </Auxilary>
        );
    }

}
