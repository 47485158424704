import React, { Component } from "react";

import Auxilary from "./Auxilary/Auxilary";

import "./Shared.css";
import jzo from "../assets/images/zoltan_juhasz.jpg";
import linkedIn from "../assets/images/linkedin.png";
import gitHub from "../assets/images/github.png";

export class Footer extends Component {

    render() {
        var marginStyle = { marginLeft: '0.5rem', marginTop: '0.3rem' };
        return (
            <Auxilary>
                <hr className="hLine" />
                <footer>
                    <div className="footerBox">
                        <div className="footerLeft">
                            <img className="footerImg" alt="Zoltán Juhász" src={jzo} />
                        </div>
                        <p>
                            Written by <strong>Zoltán Juhász</strong> who is a Full-Stack Engineer; .NET Guy; writes C#/Javascript/ReactJS code.
                                <br />
                            <a target="_blank" rel="noopener noreferer" href="https://twitter.com/JZO001">@JZO001</a>
                            <br />
                            <a href="https://www.linkedin.com/in/jzo001/" target="_blank" title="Visit my LinkedIn profile" rel="noopener noreferer">
                                <img src={linkedIn} width="21" height="21" alt="LinkedIn" style={marginStyle} />
                            </a>
                            <a href="https://github.com/JZO001/Forge" target="_blank" title="Visit my GitHub projects" rel="noopener noreferer">
                                <img src={gitHub} width="21" height="21" alt="GitHub" style={marginStyle} />
                            </a>
                        </p>
                    </div>
                </footer>
            </Auxilary>
        );
    }

}
